import React from "react";

const Vimeo = ({ source = "https://player.vimeo.com/video/76979871", className = "", ...props }) => {
  return (
    <div className={`plyr__video-embed ${className}`} id="player" {...props}>
      <iframe
        src={`${source}?loop=false&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media`}
        allowfullscreen
        // eslint-disable-next-line react/no-unknown-property
        allowtransparency
        allow="autoplay"
      ></iframe>
      {props["data-poster"] && <div className="preload_poster" style={{ background: `url(${props["data-poster"]})` }}></div>}
    </div>
  );
};

export default Vimeo;
